<script lang="ts" setup>
</script>

<template>
  <div>
    <DevOnly>
      <details>
        <summary>
          SEO Details
        </summary>
        <DebugHead />
      </details>
    </DevOnly>
  </div>
</template>

<style scoped></style>
